import React from 'react';
import NavSlider from '../NavSlider/navSlider';
import silver1 from '../../static/images/siteWork/SilverHeight/AYUS0036.jpg';
import silver2 from '../../static/images/siteWork/SilverHeight/AYUS0078_crop.jpg';
import silver4 from '../../static/images/siteWork/SilverHeight/AYUS0249.jpg';

import './style.css';

class PhotoSlider extends React.Component {
  render() {
    const settings = {
      infinite: true,
      autoplay: true,
      fade: true,
      initialSlide: 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,
      speed: 3000,
      swipeToSlide: true,
      dots: true,
    };
    const allSliderImages = [{path: silver2}, {path: silver1}, {path: silver4}];
    const sliderPhotoComponent = allSliderImages.map((photo, index) => {
      return <img alt="" key={index} src={photo.path} />;
    });
    return (
      <div className="photoSlider">
        {/* <Grid container>
          <Grid item xs={4}>
            <img alt="cover" className="cover-photo" src={silver1} />
          </Grid>
          <Grid item xs={8}>
            <img alt="cover" className="cover-photo" src={silver2} />
          </Grid>
          <Grid item xs={8}>
            <img alt="cover" className="cover-photo" src={silver4} />
          </Grid>
          <Grid item xs={4}>
            <img alt="cover" className="cover-photo" src={kasturi1} />
          </Grid>
        </Grid> */}
        <NavSlider className="team-slider" {...settings}>
          {sliderPhotoComponent}
        </NavSlider>
      </div>
    );
  }
}

export default PhotoSlider;
