import React, {Component} from 'react';
import './style.css';
import facebook from '../../static/images/facebook_64.png';
import instagram from '../../static/images/instagram_64.png';
import youtube from '../../static/images/youtube_64.png';
import {Grid, IconButton} from '@mui/material';
import Header from 'components/Header/header';
import Footer from 'components/Footer/footer';
import {withRouter} from 'react-router-dom';

class ContactUs extends Component {
  handleOpenSocialMedia = url => {
    window.open(url, '_blank');
  };

  render() {
    const isContactUsPage = this.props?.location?.pathname === '/ContactUs' ? true : false;
    return (
      <>
        {isContactUsPage && (
          <Grid item xs={12}>
            <Header />
          </Grid>
        )}
        <Grid item xs={12}>
          <div className="ContactUs">
            <div className="container">
              <div>
                <div className="title text-center h2">Contact Us</div>
                <Grid container className="traking-points">
                  <Grid item xs={12} md={6} sm={12}>
                    <div className="ContactUsPoint navkrutiMap">
                      <div>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14768.838773978146!2d70.77735187992553!3d22.27004521542718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cb1b04236ddb%3A0x5bd7e7f2000ec39!2sNavkruti%20Interior%20Architects!5e0!3m2!1sen!2sin!4v1641106495625!5m2!1sen!2sin"
                          // width="600"
                          // height="450"
                          title="navkruti"
                          style={{border: 0}}
                          allowFullScreen=""
                          loading="lazy"></iframe>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <div className="ContactUsPoint join-us">
                      <h3 className="paragraph address">Address :</h3>
                      <div className="paragraph">Twin Star. North Block- 1008</div>
                      <div className="paragraph">
                        150ft. ring road, circle, near nana mava, Rajkot.
                      </div>
                      <div className="paragraph">Gujarat 360005</div>
                      <h3 className="paragraph ">Email :</h3>
                      <div className="paragraph">navkruti.ia@gmail.com</div>
                      <h3 className="paragraph ">Phone :</h3>
                      <div className="paragraph">+919099967467</div>
                      <h3 className="paragraph ">Find Us :</h3>
                      <Grid>
                        <Grid item xs={12} md={12} className="paragraph">
                          <IconButton
                            onClick={() =>
                              this.handleOpenSocialMedia('https://www.instagram.com/navkruti.ia')
                            }>
                            <img className="social-icon" src={instagram} alt="Instagram" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              this.handleOpenSocialMedia('https://www.facebook.com/navkruti.ia')
                            }>
                            <img className="social-icon" src={facebook} alt="Facebook" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              this.handleOpenSocialMedia(
                                'https://www.youtube.com/channel/UCk6WTltkyLf_1PWNmKx0xsg',
                              )
                            }>
                            <img className="social-icon" src={youtube} alt="YouTube" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
        {isContactUsPage && (
          <>
            <Grid item xs={12}>
              <Footer />
            </Grid>
            <Grid item xs={12} md={12} className="rights">
              <p>©{new Date().getFullYear()} Navkruti. All right reserved.</p>
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default withRouter(ContactUs);
