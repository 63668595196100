import {Grid} from '@mui/material';
import React from 'react';
import Header from 'components/Header/header';
import PhotoSlider from 'components/PhotoSlider/photoSlider';
import AboutUs from 'components/AboutUs/aboutUs';
import ContactUs from 'components/ContactUs/contactUs';
import Footer from 'components/Footer/footer';
import './style.css';
import Work from 'components/Work/work';
import {withRouter} from 'react-router-dom';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.myContactRef = React.createRef();
    this.myAboutRef = React.createRef();
    this.myProjectRef = React.createRef();
  }

  componentDidMount = () => {
    // Aos.init({duration: 2000});
    // switch (location.pathname) {
    //   case '/ContactUs':
    //     this.scrollTo(this.myContactRef);
    //     break;
    //   case '/AboutUs':
    //     this.scrollTo(this.myAboutRef);
    //     break;
    //   case '/OurWork':
    //     this.scrollTo(this.myProjectRef);
    //     break;
    //   default:
    //     break;
    // }
  };

  scrollTo = ref => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  handleChangeRoute = url => {
    const {history} = this.props;
    if (history) {
      history.push(url);
    }
  };

  render() {
    return (
      <Grid container className="landing">
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <PhotoSlider />
        </Grid>
        <Grid item xs={12} ref={this.myAboutRef}>
          <div>
            <AboutUs />
          </div>
        </Grid>
        <Grid item xs={12} ref={this.myProjectRef}>
          <div>
            <Work />
          </div>
        </Grid>
        <Grid item xs={12} ref={this.myContactRef}>
          <div>
            <ContactUs />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
        <Grid item xs={12} md={12} className="rights">
          <p>©{new Date().getFullYear()} Navkruti. All right reserved.</p>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Landing);
