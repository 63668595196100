import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../static/images/navkruti_white_logo.jpg';
import './style.css';
import {withRouter} from 'react-router-dom';

class Header extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  setMobileMoreAnchorEl = val => {
    this.setState({mobileMoreAnchorEl: val});
  };

  setAnchorEl = val => {
    this.setState({anchorEl: val});
  };

  handleMobileMenuClose = () => {
    this.setMobileMoreAnchorEl(null);
  };

  handleMenuClose = () => {
    this.setAnchorEl(null);
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setMobileMoreAnchorEl(event.currentTarget);
  };

  handleChangeRoute = url => {
    const {history} = this.props;
    if (history) {
      history.push(url);
    }
  };

  render() {
    const isMenuOpen = Boolean(this.state.anchorEl);
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}>
        <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
      </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={this.state.mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}>
        <MenuItem onClick={() => this.handleChangeRoute('/')}>
          <p>Home</p>
        </MenuItem>
        <MenuItem onClick={() => this.handleChangeRoute('/AboutUs')}>
          <p>About Us</p>
        </MenuItem>
        <MenuItem onClick={() => this.handleChangeRoute('/ContactUs')}>
          <p>Contact Us</p>
        </MenuItem>
        <MenuItem onClick={() => this.handleChangeRoute('/OurWork')}>
          <p>Work</p>
        </MenuItem>
      </Menu>
    );

    return (
      <Box sx={{flexGrow: 1}} className="Navbar">
        <AppBar position="static">
          <Toolbar>
            <div className="logo">
              <img src={Logo} alt="Navkruti" />
            </div>
            <Box sx={{flexGrow: 1}} />
            <Box
              sx={{display: {xs: 'none', md: 'flex'}}}
              className="main-tabs"
              onClick={() => this.handleChangeRoute('/')}>
              <span>Home</span>
            </Box>
            <Box
              sx={{display: {xs: 'none', md: 'flex'}}}
              className="main-tabs"
              onClick={() => this.handleChangeRoute('/AboutUs')}>
              <span>About Us</span>
            </Box>
            <Box
              sx={{display: {xs: 'none', md: 'flex'}}}
              className="main-tabs"
              onClick={() => this.handleChangeRoute('/OurWork')}>
              Work
            </Box>
            <Box
              sx={{display: {xs: 'none', md: 'flex'}}}
              className="main-tabs"
              onClick={() => this.handleChangeRoute('/ContactUs')}>
              <span>Contact Us</span>
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}}}>
              <IconButton
                // size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    );
  }
}

export default withRouter(Header);
