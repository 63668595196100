import {Grid} from '@mui/material';
import Footer from 'components/Footer/footer';
import Header from 'components/Header/header';
import React from 'react';
import Founder from '../../static/images/founder.jpg';
import './style.css';
import {withRouter} from 'react-router-dom';

const AboutUs = props => {
  const isAboutUs = props?.location?.pathname === '/AboutUs' ? true : false;
  return (
    <>
      {isAboutUs && (
        <Grid item xs={12}>
          <Header />
        </Grid>
      )}
      <Grid item xs={12}>
        <div className="AboutUs">
          <div className="heading">
            <div className="h2">About Us</div>
          </div>
          <Grid container>
            <Grid item md={6} sm={12} xs={12} className="AboutUsPoint fair">
              <div className="paragraph">
                Founded in 2016, NAVKRUTI is an architectural and interior design studio based in
                Rajkot. We provide customized design solutions for residential, commercial and
                hospitality projects.
              </div>
              <div className="paragraph">
                We believe ‘Richness lies in simplicity’. Our designs are developed with the same
                sense of simplicity to achieve the product that benefits the character of purpose,
                place and people.
              </div>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <div className="AboutUsPoint professional">
                <span className="about-founder">
                  <img alt="" src={Founder} className="experience-dc" />
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {isAboutUs && (
        <>
          <Grid item xs={12}>
            <Footer />
          </Grid>
          <Grid item xs={12} md={12} className="rights">
            <p>©{new Date().getFullYear()} Navkruti. All right reserved.</p>
          </Grid>
        </>
      )}
    </>
  );
};

export default withRouter(AboutUs);
