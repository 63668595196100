import React, {Component} from 'react';
import './style.css';

import ComPhoto1 from '../../static/images/Commercial/com_1.jpg';
import ComPhoto2 from '../../static/images/Commercial/com_2.jpg';
import ComPhoto3 from '../../static/images/Commercial/com_3.jpg';
import ComPhoto4 from '../../static/images/Commercial/com_4.jpg';
import ComPhoto5 from '../../static/images/Commercial/com_5.jpg';
import RightArrow from '../../static/images/arrow-right.png';

import aqua1 from '../../static/images/siteWork/Aqau_blue/AYUS9481.jpg';
import aqua2 from '../../static/images/siteWork/Aqau_blue/AYUS9538.jpg';
import aqua3 from '../../static/images/siteWork/Aqau_blue/AYUS9717.jpg';
import aqua4 from '../../static/images/siteWork/Aqau_blue/AYUS9757.jpg';
import aqua5 from '../../static/images/siteWork/Aqau_blue/AYUS9789.jpg';

import kasturi1 from '../../static/images/siteWork/Kasturi_casa/AYUS8958.jpg';
import kasturi2 from '../../static/images/siteWork/Kasturi_casa/AYUS8967-HDR.jpg';
import kasturi3 from '../../static/images/siteWork/Kasturi_casa/AYUS8984.jpg';
import kasturi4 from '../../static/images/siteWork/Kasturi_casa/AYUS9024.jpg';
import kasturi5 from '../../static/images/siteWork/Kasturi_casa/AYUS9089.jpg';

import silver1 from '../../static/images/siteWork/SilverHeight/AYUS0036.jpg';
import silver2 from '../../static/images/siteWork/SilverHeight/AYUS0078.jpg';
import silver3 from '../../static/images/siteWork/SilverHeight/AYUS0196.jpg';
import silver4 from '../../static/images/siteWork/SilverHeight/AYUS0249.jpg';
import silver5 from '../../static/images/siteWork/SilverHeight/AYUS0333.jpg';
import silver6 from '../../static/images/siteWork/SilverHeight/AYUS9944.jpg';

import {Grid} from '@mui/material';
import Header from 'components/Header/header';
import Footer from 'components/Footer/footer';
import {withRouter} from 'react-router-dom';

class Work extends Component {
  handleChangeRoute = () => {
    const {history} = this.props;
    if (history) {
      history.push('/OurWork');
      window.scrollTo(0, 0);
    }
  };

  getSmallPhotoSection = () => {
    const allComSliderImages = [
      {path: ComPhoto1},
      {path: ComPhoto2},
      {path: ComPhoto3},
      {path: ComPhoto4},
      {path: ComPhoto5},
    ];
    const comSliderPhotoComponent = allComSliderImages.map((photo, index) => {
      return (
        <Grid item xs={12} sm={12} md={4} className="imgWrapper" key={index}>
          <div>
            <img key={index} alt={`work${index + 1}`} src={photo.path} />
          </div>
        </Grid>
      );
    });
    return (
      <Grid container spacing={1} className="smallSection">
        {comSliderPhotoComponent}
        <Grid item xs={12} sm={12} md={4} className="viewMoreWrapper">
          <div className="view-more" onClick={this.handleChangeRoute}>
            <div className="view-more-text">
              <span>View More</span>
            </div>
            <div className="view-more-icon">
              <img alt="view" src={RightArrow} />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  getBigPhotoSection = () => {
    const allComSliderImages = [
      {path: silver1},
      {path: silver2},
      {path: silver3},
      {path: silver4},
      {path: silver5},
      {path: silver6},
      {path: kasturi1},
      {path: kasturi2},
      {path: kasturi3},
      {path: kasturi4},
      {path: kasturi5},
      {path: aqua1},
      {path: aqua2},
      {path: aqua3},
      {path: aqua4},
      {path: aqua5},
      {path: ComPhoto1},
      {path: ComPhoto2},
      {path: ComPhoto3},
      {path: ComPhoto4},
      {path: ComPhoto5},
    ];
    const comSliderPhotoComponent = allComSliderImages.map((photo, index) => {
      return (
        <Grid item xs={12} sm={12} md={4} className="imgWrapper" key={index}>
          <div>
            <img key={index} alt={`work${index + 1}`} src={photo.path} />
          </div>
        </Grid>
      );
    });
    return (
      <Grid container spacing={1}>
        {comSliderPhotoComponent}
      </Grid>
    );
  };

  render() {
    const isWorkPage = this.props?.location?.pathname === '/OurWork' ? true : false;
    return (
      <>
        {isWorkPage && (
          <Grid item xs={12}>
            <Header />
          </Grid>
        )}
        <Grid item xs={12}>
          <div className="Work">
            <div className="container">
              <div>
                <div className="title text-center h2">Work</div>
              </div>
            </div>
            {isWorkPage ? this.getBigPhotoSection() : this.getSmallPhotoSection()}
          </div>
        </Grid>
        {isWorkPage && (
          <>
            <Grid item xs={12}>
              <Footer />
            </Grid>
            <Grid item xs={12} md={12} className="rights">
              <p>©{new Date().getFullYear()} Navkruti. All right reserved.</p>
            </Grid>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Work);
