import AboutUs from 'components/AboutUs/aboutUs';
import ContactUs from 'components/ContactUs/contactUs';
import Work from 'components/Work/work';
import Landing from 'container/Landing/landing';
import React from 'react';
import {Route, Switch, Router, withRouter} from 'react-router-dom';

const MainRoutes = props => {
  console.log(props);
  const history = props?.history;
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={'/'} component={props => <Landing {...props} />} />
        <Route exact path={'/ContactUs'} component={ContactUs} />
        <Route exact path={'/AboutUs'} component={props => <AboutUs {...props} />} />
        <Route exact path={'/OurWork'} component={props => <Work {...props} />} />
      </Switch>
    </Router>
  );
};

export default withRouter(MainRoutes);
