import React, {Component} from 'react';
import {Grid, IconButton} from '@mui/material';
import './style.css';
import Logo from '../../static/images/navkruti_white_logo.jpg';
import facebook from '../../static/images/facebook_64.png';
import instagram from '../../static/images/instagram_64.png';
import youtube from '../../static/images/youtube_64.png';

class Footer extends Component {
  handleOpenSocialMedia = url => {
    window.open(url, '_blank');
  };

  render() {
    return (
      <>
        <div className="Footer">
          <Grid container>
            <Grid item xs={12} md={6} className="logo">
              <img alt="navkruti" src={Logo} />
            </Grid>
            <Grid item xs={12} md={6} className="social-media">
              <IconButton
                onClick={() => this.handleOpenSocialMedia('https://www.instagram.com/navkruti.ia')}>
                <img className="social-icon" src={instagram} alt="Instagram" />
              </IconButton>
              <IconButton
                onClick={() => this.handleOpenSocialMedia('https://www.facebook.com/navkruti.ia')}>
                <img className="social-icon" src={facebook} alt="Facebook" />
              </IconButton>
              <IconButton
                onClick={() =>
                  this.handleOpenSocialMedia(
                    'https://www.youtube.com/channel/UCk6WTltkyLf_1PWNmKx0xsg',
                  )
                }>
                <img className="social-icon" src={youtube} alt="YouTube" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default Footer;
